export const API_ENDPOINTS = {
    fetchUserData: 'https://mgmdk.ru/bot/api/user/?getUserData',
    createUser: 'https://mgmdk.ru/bot/api/user/?createUser',
    fetchUserScore: 'https://mgmdk.ru/bot/api/scores/?getUserScore',
    fetchUserScoreIndex: 'https://mgmdk.ru/bot/api/scores/?getUserScoreIndex',
    fetchTopScores: 'https://mgmdk.ru/bot/api/scores/?getTopScores',
    setScore: 'https://mgmdk.ru/bot/api/scores/?setScore',
}

export const INITIAL_USER = {
    allowsWriteToPm: true,
    firstName: "Мухаммад",
    id: 4445,
    isPremium: false,
    languageCode: "en",
    lastName: "Last name",
    userName: "default_user3",
};
