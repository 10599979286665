import React, {useState} from "react";
import './styles.css';
import {Provider} from "react-redux";
import {realtyStore} from "./store/index.ts";
import {Housings} from "./pages/Housings/index.tsx";
import {HousingComplex} from "./pages/HousingComplex/index.tsx";
import { ReactComponent as Chevron} from './assets/icons/chevron.svg';
import {Header} from "./components/Header/index.tsx";

export const Realty = ({
                       }) => {
    const [selectedHousing, setSelectedHousing] = useState();
    return (
        <>
            <Provider store={realtyStore}>
                <Header />
                {selectedHousing ? (
                    <>
                        <a className="back-link" onClick={() => setSelectedHousing()}>
                            <div className="chevron"><Chevron width="16" height="16" fill="#000"/></div>
                            к списку
                        </a>
                        <HousingComplex housing={selectedHousing}/>
                    </>
                ) : (
                    <Housings setSelectedHousing={setSelectedHousing}/>
                )}
            </Provider>
        </>
    );
};
