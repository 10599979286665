import Phaser from "phaser";
import {gameOptions, config} from '../pages/Game/index.tsx';
import {store} from "../store/index.ts";
import {ActionCreator} from "../store/actions.ts";

export class playGame extends Phaser.Scene {
    private maxHeight = 0;
    private maxY = undefined;
    private cookieHeight = 20;
    private lastCookie = null;
    private preLastCookie = null;
    private count = 0;
    private isGrounded = false;
    private isDefeat = false;
    private currentCookie = 'cookie';
    private randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }

    constructor() {
        super("PlayGame");
    }

    create() {
        this.matter.world.update30Hz();
        this.canDrop = true;
        this.timer = 0;
        this.timerEvent = null;
        this.addSky();
        this.addPlate();
        this.addGround();
        this.addMovingCookie();
        this.currentScore = this.add.bitmapText(10, 10, "font", `0`, 72);
        this.maxScore = this.add.bitmapText(10, 60, "font", this.registry.get('maxScore').toString(), 72);
        this.cookieGroup = this.add.group();
        this.matter.world.on("collisionstart", this.checkCollision, this);
        this.setCameras();
        this.input.on("pointerdown", this.dropCookie, this);
        this.maxY = config.height;
        this.registry.events.on('changedata', this.updateScore, this);
    }

    updateScore(parent, key, data) {
        //console.log(key, data);
    }

    addSky() {
        this.sky = this.add.sprite(0, 0, "sky");
        this.sky.displayWidth = config.width;
        this.sky.setOrigin(0, 0);
    }

    addPlate() {
        this.groundWithPlate = this.add.sprite(0, 0, "groundWithPlate");
        // this.groundWithPlate.setBody({
        //     type: "rectangle",
        //     width: this.groundWithPlate.displayWidth,
        //     height: 256,
        // });
        this.groundWithPlate.displayWidth = config.width;
        this.groundWithPlate.displayHeight = config.height;
        this.groundWithPlate.setOrigin(0, 0);
        //this.groundWithPlate.setStatic(true);
    }

    addGround() {
        this.ground = this.matter.add.sprite(config.width / 2, config.height, "ground");
        this.ground.setBody({
            type: "rectangle",
            width: 220,
            height: this.ground.displayHeight
        });
        this.ground.setOrigin(0.5, 1);
        this.ground.setStatic(true);
        this.ground.setVisible(false);
    }

    addMovingCookie() {
        console.log(this.currentCookie)
        this.movingCookie = this.add.sprite(config.width / 2 - gameOptions.cookieRange[0], this.ground.getBounds().top - gameOptions.startCookieHeight, this.currentCookie);
        this.tweens.add({
            targets: this.movingCookie,
            x: config.width / 2 - gameOptions.cookieRange[1],
            duration: gameOptions.cookieSpeed,
            yoyo: true,
            repeat: -1
        })
    }

    checkCollision(e, b1, b2) {
        const isLast = b1.lastCookie || b2.lastCookie;
        let groundHit = isLast && ((b1.isCookie && !b2.isCookie) || (!b1.isCookie && b2.isCookie));
        // console.log({'b1': {
        //     isCookie: b1.isCookie,
        //         hit: b1.hit,
        //     }, 'b2': {
        //     isCookie: b2.isCookie,
        //     hit: b2.hit
        // },
        // 'groundhit': groundHit, 'last': isLast});
        // console.log(this.lastCookie)
        //console.log(b1.isChoco, b1.lastCookie, b2.isChoco, b2.lastCookie)
        if ((b1.lastCookie && b1.isChoco) || (b2.lastCookie && b2.isChoco)) {
            console.log('choco hit')
            this.cookieGroup.getChildren().forEach((cookie, index) => {
                cookie.setStatic(1);
                cookie.body.position.x = b2.position.x;
            })

        }
        if (b1.isCookie && !b1.hit) {
            b1.hit = true;
            b1.lastCookie = false;
            this.nextCookie();
        }
        if (b2.isCookie && !b2.hit) {
            b2.hit = true;
            b2.lastCookie = false;
            this.nextCookie();
        }
        if (groundHit && !this.isGrounded) {
            this.isGrounded = true;
            groundHit = false;
        }
        if (groundHit && this.isGrounded) {
            console.log('isDefeat')
            this.isDefeat = true;
        }
    }

    setCameras() {
        this.actionCamera = this.cameras.add(0, 0, config.width, config.height);
        this.actionCamera.ignore([this.sky, this.currentScore]);
        this.actionCamera.ignore([this.sky, this.maxScore]);
        this.cameras.main.ignore([this.ground, this.movingCookie]);
        this.cameras.main.ignore([this.groundWithPlate, this.movingCookie]);
    }

    dropCookie() {
        if (this.canDrop && this.timer < gameOptions.timeLimit) {
            this.canDrop = false;
            this.movingCookie.visible = false;
            this.addFallingCookie();
        }
        this.count = this.cookieGroup.getChildren().length;
        this.cookieGroup.getChildren().forEach((cookie, index) => {
            if ((this.count - 1) - index > 3) {
                cookie.setStatic(1);
            }
        })
        if (this.count > 0) {
            this.lastCookie = this.cookieGroup.getChildren()[this.count - 1];
        }
        if (this.count > 1) {
            this.preLastCookie = this.cookieGroup.getChildren()[this.count - 2];
        }
    }

    update() {
        this.checkDefeat();
        this.aimHelp();
        //this.autoAim();
        this.cookieGroup.getChildren().forEach(function (cookie) {
            if (cookie.y > config.height + cookie.displayHeight) {
                if (!cookie.body.hit) {
                    this.isDefeat = true;
                }
            }
        }, this);
    }

    aimHelp() {
        if (this.preLastCookie?.body && Math.abs(this.preLastCookie.body.position.x - this.lastCookie.body.position.x) < 15) {
            this.lastCookie.body.position.x = this.preLastCookie.body.position.x;
            //this.lastCookie.body.angle = 180;
        }
    }

    autoAim() {
        if (this.preLastCookie?.body) {
            this.lastCookie.body.position.x = this.preLastCookie.body.position.x;
        }
    }

    checkDefeat() {
        if (this.lastCookie?.body) {
            let verticalDiff = 0;
            let angle = 0;
            let targetCookiePositionY = undefined;
            if (this.lastCookie.body.hit) {
                targetCookiePositionY = this.lastCookie.body.position.y;
            } else if (!this.lastCookie.body.hit && this.preLastCookie?.body) {
                targetCookiePositionY = this.preLastCookie.body.position.y;
            }
            verticalDiff = targetCookiePositionY - this.maxY;
            //console.log(targetCookiePositionY, this.maxY)
            angle = Math.abs(this.lastCookie.body.angle);
            if (verticalDiff > this.cookieHeight || this.isDefeat || angle > 0.5) {
                //console.log(verticalDiff, angle)
                this.maxHeight = 0;
                this.maxY = undefined;
                //this.cookieHeight = null;
                this.lastCookie = null;
                this.preLastCookie = null;
                store.dispatch(ActionCreator.setScore({id: this.registry.get('user')?.id, value: this.count}));
                this.count = 0;
                this.isDefeat = false;
                this.isGrounded = false;
                this.cookieGroup.clear();
                this.scene.start("GameOver");
                this.scene.stop();
            }
        }
    }

    addFallingCookie() {
        let fallingCookie = this.matter.add.sprite(this.movingCookie.x, this.movingCookie.y, this.currentCookie, { shape: {
                type: 'rectangle',
                width: 75,
                height: 20,
            } });
        fallingCookie.setRectangle(75, 20);
        fallingCookie.body.isCookie = true;
        fallingCookie.body.lastCookie = true;
        fallingCookie.body.hit = false;
        fallingCookie.setBounce(0.3);
        fallingCookie.setInteractive();
        if (this.currentCookie === 'chocoCookie') {
            fallingCookie.body.isChoco = true;
        }
        this.cookieGroup.add(fallingCookie);
        this.cameras.main.ignore(fallingCookie);
    }

    nextCookie() {
        this.moveCamera();
        this.canDrop = true;
        const random = this.randomInteger(0, 10);
        console.log(random)
        this.currentCookie = random === 6 ? 'chocoCookie' : 'cookie';
        this.movingCookie.setTexture(this.currentCookie);
        this.movingCookie.visible = true;
        this.currentScore.text = this.count.toString();
    }

    moveCamera() {
        this.cookieGroup.getChildren().forEach(function (cookie) {
            // if (this.cookieHeight === 0) {
            //     this.cookieHeight = this.ground.getBounds().top - cookie.getBounds().top;
            // }
            if (cookie.body.hit) {
                this.maxHeight = Math.max(this.maxHeight, (this.ground.getBounds().top - cookie.getBounds().top));
                this.maxY = Math.min(this.maxY, (cookie.body.position.y));
            }
        }, this);
        if (this.maxHeight > this.cookieHeight) {
            //this.movingCookie.y = this.ground.getBounds().top - this.maxHeight * 0.5 - gameOptions.cookieHeight - this.cookieHeight;
            //this.actionCamera.pan(config.width / 2, config.height / 2 - this.maxHeight * 0.5 - this.cookieHeight, 500);
            this.movingCookie.y = this.maxY - gameOptions.cookieHeight;
            this.actionCamera.pan(config.width / 2, this.maxY - gameOptions.cookieHeight + (this.cookieHeight * 7), 500)
        }
    }
}
