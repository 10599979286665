import {RootState} from "./index.ts";

export const selectUserPending = (state: RootState) => state.userReducer.pending;

export const selectUserData = (state: RootState) => state.userReducer.data;

export const selectTopScores = (state: RootState) => state.userReducer.topScores;

export const selectTopScoresPending = (state: RootState) => selectTopScores(state).pending;

export const selectTopScoresError = (state: RootState) => selectTopScores(state).error;

export const selectTopScoresList = (state: RootState) => selectTopScores(state).data;

export const selectUserScore = (state: RootState) => state.userReducer.score;

export const selectUserScorePending = (state: RootState) => selectUserScore(state).pending;

export const selectUserScoreError = (state: RootState) => selectUserScore(state).error;

export const selectUserScoreValue = (state: RootState) => selectUserScore(state).value;

export const selectUserScoreIndex = (state: RootState) => selectUserScore(state).index;

export const selectUserScoreIndexPending = (state: RootState) => selectUserScoreIndex(state).pending;

export const selectUserScoreIndexError = (state: RootState) => selectUserScoreIndex(state).error;

export const selectUserScoreIndexValue = (state: RootState) => selectUserScoreIndex(state).value;
