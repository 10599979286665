import React from "react";
import {UserBar} from "../../components/UserBar/index.tsx";
import {ScoreBoardComponent} from "../../components/ScoreBoardComponent/index.tsx";

export const ScoreBoard = () => (
    <>
        <UserBar/>
        <ScoreBoardComponent/>
    </>
);


