import React, {useState} from "react";
import {INITIAL_USER} from "../../constants/common.ts";
import {Link} from "react-router-dom";
import {RootState} from "../../store/index.ts";
import {
    selectUserData, selectUserPending,
    selectUserScoreIndexValue,
    selectUserScoreValue
} from "../../store/selectors.ts";
import {ActionCreator} from "../../store/actions.ts";
import {connect} from "react-redux";
import './styles.css';
import {useCreateUser, useFetchUserData, useFetchUserScoreInfo, useSetTelegramUserData} from "./hooks.ts";
import {UserBarPropsType} from "./types.ts";
import {isLocalhost} from "./utils.ts";

export const _UserBar = ({
                             userData,
                             pending,
                             createUser,
                             userScore,
                             userScoreIndex,
                             fetchUserScore,
                             fetchUserScoreIndex,
                             fetchUserData,
                         }: UserBarPropsType) => {
    const [userInfo, setUserInfo] = useState(isLocalhost ? INITIAL_USER : undefined);

    useSetTelegramUserData(setUserInfo);

    useFetchUserData({userInfo, fetchUserData});

    useCreateUser({pending, userInfo, userData, createUser});

    useFetchUserScoreInfo({userData, fetchUserScore, fetchUserScoreIndex});

    return (
        <div className="user-bar">
            <Link to={'/'} className="user-bar-item">
                {userData?.userName}
            </Link>
            <Link to='/scores' className='user-bar-item'>рекорды</Link>
            <div className="user-bar-item">
                мой рекорд: {userScore}
            </div>
            <div className="user-bar-item">
                позиция в рейтинге: {userScoreIndex}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    userData: selectUserData(state),
    pending: selectUserPending(state),
    userScore: selectUserScoreValue(state),
    userScoreIndex: selectUserScoreIndexValue(state),
});

const mapDispatchToProps = {
    createUser: ActionCreator.createUser,
    fetchUserData: ActionCreator.fetchUserData,
    fetchUserScore: ActionCreator.fetchUserScore,
    fetchUserScoreIndex: ActionCreator.fetchUserScoreIndex,
}

export const UserBar = connect(mapStateToProps, mapDispatchToProps)(_UserBar);
