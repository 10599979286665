import Phaser from "phaser";
import {config} from "../pages/Game/index.tsx";

export class gameOver extends Phaser.Scene {
    constructor() {
        super("GameOver");
    }

    create() {
        //this.input.on("pointerdown", () => this.scene.start("PlayGame"), this);
        //this.scene.start("playGame");
        this.gameOverText = this.add.text(config.width / 2, config.height / 2, 'game over', 72).setOrigin(0.5, 0.5);
        this.startButton = this.add.sprite(config.width / 2, config.height / 2, "replayButton");
        this.input.on("pointerdown", () => {
            this.scene.stop();
            this.scene.start("MainMenu");
        }, this);
    }
}
