import React, {useEffect} from "react";
import Phaser from "phaser";

export const GameComponent = ({ config }) => {

    useEffect(() => {
        const game = new Phaser.Game(config);

        return () => {
            game.destroy(true);
        }
    }, []);

    return <div id="phaser-container" />
}
