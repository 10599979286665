import {createReducer} from "@reduxjs/toolkit";
import {ActionCreator} from "./actions.ts";

const initialState = {
    pending: true,
    error: false,
    data: {
        userId: null,
        firstName: null,
        lastName: null,
        userName: null,
        isPremium: false,
        allowsWriteToPm: false,
    },
    createUser: {
        pending: false,
        errorMessage: null,
        isSucceeded: false,
    },
    score: {
        pending: true,
        error: false,
        value: 0,
        index: {
            pending: true,
            error: false,
            value: 0,
        },
    },
    topScores: {
        pending: true,
        error: false,
        data: null,
    }
}

export const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(ActionCreator.fetchUserData.pending, (state) => {
            state.pending = true;
        })
        .addCase(ActionCreator.fetchUserData.fulfilled, (state, action) => {
            state.pending = false;
            state.error = false;
            state.data = action.payload;
        })
        .addCase(ActionCreator.fetchUserData.rejected, (state) => {
            state.pending = false;
            state.error = true;
        })
        .addCase(ActionCreator.createUser.pending, (state) => {
            state.createUser.pending = true;
            state.createUser.isSucceeded = false;
        })
        .addCase(ActionCreator.createUser.fulfilled, (state, action) => {
            //console.log(action.payload)
            state.createUser.pending = false;
            state.createUser.errorMessage = null;
            state.createUser.isSucceeded = true;
            state.data = action.payload.userData;
        })
        .addCase(ActionCreator.createUser.rejected, (state, action) => {
            //console.log(action.payload)
            state.createUser.pending = false;
            state.createUser.isSucceeded = false;
            state.createUser.errorMessage = 'Unknown error';
        })
        .addCase(ActionCreator.fetchUserScore.pending, (state) => {
            state.score.pending = true;
        })
        .addCase(ActionCreator.fetchUserScore.fulfilled, (state, action) => {
            state.score.pending = false;
            state.score.error = false;
            state.score.value = action.payload.score;
        })
        .addCase(ActionCreator.fetchUserScore.rejected, (state) => {
            state.score.pending = false;
            state.score.error = true;
        })
        .addCase(ActionCreator.fetchUserScoreIndex.pending, (state) => {
            state.score.index.pending = true;
        })
        .addCase(ActionCreator.fetchUserScoreIndex.fulfilled, (state, action) => {
            state.score.index.pending = false;
            state.score.index.error = false;
            state.score.index.value = action.payload.index;
        })
        .addCase(ActionCreator.fetchUserScoreIndex.rejected, (state) => {
            state.score.index.pending = false;
            state.score.index.error = true;
        })
        .addCase(ActionCreator.fetchTopScores.pending, (state) => {
            state.topScores.pending = true;
        })
        .addCase(ActionCreator.fetchTopScores.fulfilled, (state, action) => {
            state.topScores.pending = false;
            state.topScores.error = false;
            state.topScores.data = action.payload;
        })
        .addCase(ActionCreator.fetchTopScores.rejected, (state) => {
            state.topScores.pending = false;
            state.topScores.error = true;
        })
        .addCase(ActionCreator.setScore.fulfilled, (state, action) => {
            if (action.payload.status === 'ok') {
                state.score.value = action.payload.score.score_value;
            }
        })
});
