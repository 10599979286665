import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {apiClient} from "../utils/index.ts";
import {API_ENDPOINTS} from "../constants/common.ts";

const ActionTypes = {
    FETCH_FLATS_TABLE: 'flatsTable/fetch',
    FETCH_PRICES_BY_FIRST_PAYMENT: 'pricesByFirstPayment/fetch',
    FETCH_PRICES_BY_PERIOD: 'pricesByPeriod/fetch',
    FETCH_HOUSINGS: 'housings/fetch',
    FETCH_INSTALLMENT_PLAN_TYPES: 'installmentPlanTypes/fetch',
};

export const ActionCreator = {
    fetchFlatsTable: createAsyncThunk(ActionTypes.FETCH_FLATS_TABLE, async (id: number) => {
        const result = await apiClient.post(API_ENDPOINTS.flatsTable);

        return result.data;
    }),
    fetchHousings: createAsyncThunk(ActionTypes.FETCH_HOUSINGS, async (id: number) => {
        const result = await apiClient.post(API_ENDPOINTS.housings);

        return result.data;
    }),
    fetchPricesByFirstPayment: createAsyncThunk(ActionTypes.FETCH_PRICES_BY_FIRST_PAYMENT, async (housingName: string) => {
        const result = await apiClient.post(API_ENDPOINTS.housingPricesByFirstPayment, {
            housingName,
        });

        return result.data;
    }),
    fetchPricesByPeriod: createAsyncThunk(ActionTypes.FETCH_PRICES_BY_PERIOD, async (housingName: string) => {
        console.log(housingName)
        const result = await apiClient.post(API_ENDPOINTS.housingPricesByPeriod, {
            housingName,
        });

        return result.data;
    }),
    fetchInstallmentPlanTypes: createAsyncThunk(ActionTypes.FETCH_INSTALLMENT_PLAN_TYPES, async (housingName: string) => {
        console.log(housingName)
        const result = await apiClient.post(API_ENDPOINTS.installmentPlanTypes, {
            housingName,
        });

        return result.data;
    }),
};
