import React, {useEffect} from "react";
import {RootState} from "../../store/index.ts";
import {selectTopScoresError, selectTopScoresList, selectTopScoresPending} from "../../store/selectors.ts";
import {ActionCreator} from "../../store/actions.ts";
import {connect} from "react-redux";
import './styles.css';

export const _ScoreBoardComponent = ({
                                         pending,
                                         error,
                                         scoresList,
                                         fetchTopScores,
}) => {
    useEffect(() => {
        fetchTopScores();
    }, []);

    if (pending || scoresList.length < 1) {
        return null;
    }
    console.log(scoresList);
    return (
        <table>
            {scoresList.map((scoreItem, index) => (
                <tr className={index % 2 === 0 ? 'odd' : 'even'}>
                    <td>{index + 1}</td>
                    <td>{scoreItem.userName}</td>
                    <td>{scoreItem.score}</td>
                </tr>
            ))}
        </table>
    );
};

const mapStateToProps = (state: RootState) => ({
    pending: selectTopScoresPending(state),
    error: selectTopScoresError(state),
    scoresList: selectTopScoresList(state),
});

const mapDispatchToProps = {
    fetchTopScores: ActionCreator.fetchTopScores,
};

export const ScoreBoardComponent = connect(mapStateToProps, mapDispatchToProps)(_ScoreBoardComponent);
