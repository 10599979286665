import React, {useEffect, useState} from "react";
import './styles.css'
import {SelectInstallmentOption} from "../components/SelectInstallmentOption/index.tsx";
import {getAreaWorth, getInstallmentPeriods, getMarkup} from "./utils.ts";
import {Input} from "../../Input/index.tsx";
import {RootState} from "../../../../store/index.ts";
import {ActionCreator} from "../../../../store/actions.ts";
import {connect} from "react-redux";
import {selectPricesByPeriodData, selectPricesByPeriodPending} from "../../../../store/selectors.ts";
import {getAreas, getFlatViewTypes, getFloorRanges} from "../utils.ts";
import {ByPeriodSkeleton} from "./components/ByPeriodSkeleton/index.tsx";

export const _ByPeriod = ({
    pending,
                              housingName,
                              pricesByPeriod,
                              fetchPricesByPeriod
                          }) => {
    const [installmentPeriod, setInstallmentPeriod] = useState(0);
    const [floorRange, setFloorRange] = useState();
    const [flatViewType, setFlatViewType] = useState();
    const [areaWorth, setAreaWorth] = useState(0);
    const [firstPayment, setFirstPayment] = useState(0);
    const [fullPrice, setFullPrice] = useState(0);
    const [rest, setRest] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [markup, setMarkup] = useState(0);
    const [area, setArea] = useState(0);

    const floorRanges = getFloorRanges(pricesByPeriod);
    const flatViewTypes = getFlatViewTypes(floorRange);
    const areas = getAreas(pricesByPeriod);
    const installmentPeriods = getInstallmentPeriods(floorRange);
    const isFullPayment = installmentPeriod?.value?.percent !== 'full';

    console.log(installmentPeriods, installmentPeriod, area, floorRange);

    useEffect(() => {
        if (installmentPeriod) {
            setAreaWorth(getAreaWorth(floorRange, flatViewType, installmentPeriod));
            console.log(getAreaWorth(floorRange, flatViewType, installmentPeriod))
        }
    }, [installmentPeriod, flatViewType]);

    useEffect(() => {
        if (installmentPeriods) {
            setInstallmentPeriod(installmentPeriods?.full);
        }
    }, [pending]);

    useEffect(() => {
        fetchPricesByPeriod(housingName);
    }, []);

    useEffect(() => {
        if (area.value > 0 && areaWorth > 0) {
            console.log(area, areaWorth, area.value * areaWorth)
            setFullPrice(area.value * areaWorth);
        }
        if (firstPayment > 0 && fullPrice > 0) {
            setRest((fullPrice + markup) - firstPayment);
        }
        if (fullPrice > 0) {
            setMarkup(getMarkup(fullPrice, installmentPeriod));
            const monthlyPayment = ((fullPrice + markup) - firstPayment) / installmentPeriod.value.period;
            setMonthlyPayment(Math.round(monthlyPayment));
        }
    });

    if (pending) {
        return <ByPeriodSkeleton />
    }

    return (
        <>
            <SelectInstallmentOption
                options={floorRanges}
                label="выберите этажи"
                selectedOption={floorRange}
                setSelectedOption={setFloorRange}
            />
            {floorRange && (
                <>
                    <SelectInstallmentOption
                        options={flatViewTypes}
                        label="выберите тип квартиры"
                        selectedOption={flatViewType}
                        setSelectedOption={setFlatViewType}
                    />
                    <SelectInstallmentOption
                        options={areas}
                        label="выберите квадратуру"
                        selectedOption={area}
                        setSelectedOption={setArea}
                    />
                    <SelectInstallmentOption
                        options={installmentPeriods}
                        label="выберите срок рассрочки"
                        selectedOption={installmentPeriod}
                        setSelectedOption={setInstallmentPeriod}
                    />
                </>
            )}
            <div className="first-payment_container">
                <Input
                    name="areaWorth"
                    type="number"
                    placeholder="0 ₽"
                    value={areaWorth > 0 && areaWorth}
                    onChange={setAreaWorth}
                    label="стоимость квадрата:"
                />
                {isFullPayment && (
                    <Input
                        name="firstPayment"
                        type="number"
                        placeholder="0 ₽"
                        value={firstPayment > 0 && firstPayment}
                        onChange={setFirstPayment}
                        label="первоначальный взнос:"
                    />
                )}
                <div className="first-payment_result">
                    полная стоимость:
                    <div className="first-payment_numbers">
                        {(fullPrice + markup).toLocaleString()} ₽
                    </div>
                </div>
                {firstPayment > 0 && (
                    <div className="first-payment_result">
                        остаток:
                        <div className="first-payment_numbers">
                            {rest.toLocaleString()} ₽
                        </div>
                    </div>)}
                {isFullPayment && (
                    <>
                        <div className="first-payment_result">
                            ежемесячный платеж:
                            <div className="first-payment_numbers">
                                {monthlyPayment.toLocaleString()} ₽
                            </div>
                        </div>
                        <div className="first-payment_result">
                            наценка:
                            <div className="first-payment_numbers">
                                {markup.toLocaleString()} ₽
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    pricesByPeriod: selectPricesByPeriodData(state),
    pending: selectPricesByPeriodPending(state),
});

const mapDispatchToProps = {
    fetchPricesByPeriod: ActionCreator.fetchPricesByPeriod,
};

export const ByPeriod = connect(mapStateToProps, mapDispatchToProps)(_ByPeriod);
