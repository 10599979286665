import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {apiClient} from "../utils/index.ts";
import {API_ENDPOINTS} from "../constants/common.ts";

const ActionTypes = {
    CREATE_USER: 'user/create',
    FETCH_USER_DATA: 'userData/fetch',
    FETCH_USER_SCORE: 'userScore/fetch',
    FETCH_USER_SCORE_INDEX: 'userScoreIndex/fetch',
    FETCH_TOP_SCORES: 'topScores/fetch',
    SET_SCORE: 'score/set',
    SET_LOCAL_SCORE: 'localScore/set',
    UNSET_LOCAL_SCORE: 'localScore/unset',
};

export const ActionCreator = {
    fetchUserData: createAsyncThunk(ActionTypes.FETCH_USER_DATA, async (id: number) => {
        const result = await apiClient.post(API_ENDPOINTS.fetchUserData, {
            id,
        });

        return result.data;
    }),
    createUser: createAsyncThunk(ActionTypes.CREATE_USER, async (userData) => {
        const result = await apiClient.post(API_ENDPOINTS.createUser, {
            ...userData,
        });

        return result.data;
    }),
    fetchUserScore: createAsyncThunk(ActionTypes.FETCH_USER_SCORE, async (id: number) => {
        const result = await apiClient.post(API_ENDPOINTS.fetchUserScore, {
            userId: id,
        });

        return result.data;
    }),
    fetchUserScoreIndex: createAsyncThunk(ActionTypes.FETCH_USER_SCORE_INDEX, async (id: number) => {
        const result = await apiClient.post(API_ENDPOINTS.fetchUserScoreIndex, {
            userId: id,
        });

        return result.data;
    }),
    fetchTopScores: createAsyncThunk(ActionTypes.FETCH_TOP_SCORES, async () => {
        const result = await apiClient.get(API_ENDPOINTS.fetchTopScores);

        return result.data;
    }),
    setScore: createAsyncThunk(ActionTypes.SET_SCORE, async ({id, value}) => {
        const result = await apiClient.post(API_ENDPOINTS.setScore, {
            userId: id,
            scoreValue: value,
        });

        return result.data;
    }),
    setLocalScore: createAction(ActionTypes.SET_LOCAL_SCORE),
    unsetLocalScore: createAction(ActionTypes.UNSET_LOCAL_SCORE),
};
