import React, {useEffect, useState} from "react";
import './styles.css';
import {ActionCreator} from "../../store/actions.ts";
import {RootState} from "../../store/index.ts";
import {selectHousingsList, selectHousingsPending} from "../../store/selectors.ts";
import {connect} from "react-redux";
import {HousingItem} from "./HousingItem/index.tsx";
import {HousingsSkeleton} from "./HousingsSkeleton/index.tsx";
import {Search} from "../../components/Search/index.tsx";

export const _Housings = ({
                              pending,
                              housings,
                              fetchHousings,
    setSelectedHousing,
                          }) => {
    const [searchResults, setSearchResults] = useState();
    const housingArray = searchResults || housings;
    useEffect(() => {
        fetchHousings();
    }, []);

    if (pending) {
        return <HousingsSkeleton />;
    }

    console.log(searchResults);

    return (
        <>
            <div className="housings-search-bar">
                <Search onResult={setSearchResults} housings={housings} />
            </div>
            <ul className="housings-list">
                {housingArray.map(housing => (
                    <HousingItem
                        housing={housing}
                        onClick={() => setSelectedHousing(housing)}
                    />
                ))}
            </ul>
        </>
    );
};

const mapDispatchToProps = {
    fetchFlatsTable: ActionCreator.fetchFlatsTable,
    fetchHousings: ActionCreator.fetchHousings,
};

const mapStateToProps = (state: RootState) => ({
    pending: selectHousingsPending(state),
    housings: selectHousingsList(state),
});

export const Housings = connect(mapStateToProps, mapDispatchToProps)(_Housings);
