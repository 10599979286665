export const getMarkup = (price, installmentPeriod) => {
    let markup = 0;
    if (installmentPeriod.value.percent === 'full') {
        return 0;
    }
    const percent = installmentPeriod.value.percent.replace('%', '');
    if (price > 0) {
        markup = (percent / 100) * price;
    }
    return markup;
};

export const getInstallmentPeriods = (floorRange) => {
    let installmentPeriods = {
        full: {
            name: 'полная стоимость',
            value: {
                period: 1,
                percent: 'full',
            }
        }
    };
    floorRange?.value?.prices.map(priceItem => {
        if (priceItem.installmentPeriod) {
            installmentPeriods[priceItem.installmentPeriod] = {
                name: `${priceItem.installmentPeriod} месяцев (${priceItem.installmentPercent})`,
                value: {
                    period: priceItem.installmentPeriod,
                    percent: priceItem.installmentPercent,
                },
            };
        }
    });
    return installmentPeriods;
};

export const getAreaWorth = (floorRange, flatViewType, installmentPeriod) => {
    let areaWorth = 0;
    floorRange?.value?.prices?.map(item => {
        if (item.installmentPercent === installmentPeriod?.value?.percent) {
            areaWorth = item[flatViewType?.value?.id];
        }
    });
    return areaWorth;
};
