import {createReducer} from "@reduxjs/toolkit";
import {ActionCreator} from "./actions.ts";

const initialState = {
    flats: {
        pending: true,
        error: false,
        data: null,
    },
    housings: {
        pending: true,
        error: false,
        data: null,
    },
    housingPricesByFirstPayment: {
        pending: true,
        error: false,
        data: null,
    },
    housingPricesByPeriod: {
        pending: true,
        error: false,
        data: null,
    },
    installmentPlanTypes: {
        pending: true,
        error: false,
        data: null,
    }
};

export const commonReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(ActionCreator.fetchFlatsTable.pending, (state) => {
            state.flats.pending = true;
        })
        .addCase(ActionCreator.fetchFlatsTable.fulfilled, (state, action) => {
            state.flats.pending = false;
            state.flats.error = false;
            state.flats.data = action.payload;
        })
        .addCase(ActionCreator.fetchFlatsTable.rejected, (state) => {
            state.flats.pending = false;
            state.flats.error = true;
        })
        .addCase(ActionCreator.fetchHousings.pending, (state) => {
            state.housings.pending = true;
        })
        .addCase(ActionCreator.fetchHousings.fulfilled, (state, action) => {
            state.housings.pending = false;
            state.housings.error = false;
            state.housings.data = action.payload;
        })
        .addCase(ActionCreator.fetchHousings.rejected, (state) => {
            state.housings.pending = false;
            state.housings.error = true;
        })
        .addCase(ActionCreator.fetchInstallmentPlanTypes.pending, (state) => {
            state.installmentPlanTypes.pending = true;
        })
        .addCase(ActionCreator.fetchInstallmentPlanTypes.fulfilled, (state, action) => {
            state.installmentPlanTypes.pending = false;
            state.installmentPlanTypes.error = false;
            state.installmentPlanTypes.data = action.payload;
        })
        .addCase(ActionCreator.fetchInstallmentPlanTypes.rejected, (state) => {
            state.installmentPlanTypes.pending = false;
            state.installmentPlanTypes.error = true;
        })
        .addCase(ActionCreator.fetchPricesByFirstPayment.pending, (state) => {
            state.housingPricesByFirstPayment.pending = true;
        })
        .addCase(ActionCreator.fetchPricesByFirstPayment.fulfilled, (state, action) => {
            state.housingPricesByFirstPayment.pending = false;
            state.housingPricesByFirstPayment.error = false;
            state.housingPricesByFirstPayment.data = action.payload;
        })
        .addCase(ActionCreator.fetchPricesByFirstPayment.rejected, (state) => {
            state.housingPricesByFirstPayment.pending = false;
            state.housingPricesByFirstPayment.error = true;
        })
        .addCase(ActionCreator.fetchPricesByPeriod.pending, (state) => {
            state.housingPricesByPeriod.pending = true;
        })
        .addCase(ActionCreator.fetchPricesByPeriod.fulfilled, (state, action) => {
            state.housingPricesByPeriod.pending = false;
            state.housingPricesByPeriod.error = false;
            state.housingPricesByPeriod.data = action.payload;
        })
        .addCase(ActionCreator.fetchPricesByPeriod.rejected, (state) => {
            state.housingPricesByPeriod.pending = false;
            state.housingPricesByPeriod.error = true;
        })
});
