import Phaser from "phaser";
import {config} from "../pages/Game/index.tsx";
import startButton from "../assets/sprites/interface/startButton.png";
import replayButton from "../assets/sprites/interface/replayButton.png";
import ground from '../assets/sprites/ground.png';
import sky from '../assets/sprites/sky.png';
import wallpaper from '../assets/sprites/wallpaper.png';
import groundWithPlate from '../assets/sprites/groundWithPlate.png';
import cookie from "../assets/sprites/default_cookie.png";
import chocoCookie from "../assets/sprites/choco_cookie.png";
import fontPng from "../assets/fonts/font.png";
import fontFnt from "../assets/fonts/font.fnt";
import {store} from "../store/index.ts";

let startText;

export class mainMenu extends Phaser.Scene {
    constructor() {
        super("MainMenu");
    }

    resetGame = () => {
        this.scene.get("PlayGame").scene.restart();
        this.scene.stop();
    };

    preload() {
        this.load.image("ground", ground);
        this.load.image("groundWithPlate", groundWithPlate);
        this.load.image("sky", sky);
        this.load.image("wallpaper", wallpaper);
        this.load.image("cookie", cookie);
        this.load.image("chocoCookie", chocoCookie);
        this.load.bitmapFont("font", fontPng, fontFnt);
        this.load.image("startButton", startButton);
        this.load.image("replayButton", replayButton);
    }

    create() {
        store.subscribe(() => this.stateUpdate());
        this.input.on("pointerdown", () => {
            if (this.registry.get('user')?.userName) {
                this.resetGame();
            }
        }, this);
        this.addSky();
        //this.addGround();
        this.startButton = this.add.sprite(config.width / 2, config.height / 2, "startButton");
        startText = this.startText = this.add.text(config.width / 2, 0, this.registry.get('user')?.userName || 'pending',  144);
    }

    update() {
        // this.registry.events.on('changedata', () =>
        //     this.startText.setText(this.registry.get('user'))?.userName, this);
    }

    stateUpdate() {
        const newState = store.getState();
        //console.log(newState);
        const maxScore = newState.userReducer.score.value;
        if (newState.userReducer.data?.userName && !newState.userReducer.pending) {
            this.registry.set('user', newState.userReducer.data);
            this.startText.setText(newState.userReducer.data?.userName);
            //console.log(newState.userReducer.scores.data)
            this.registry.set('maxScore', maxScore);
        }
    }

    addSky() {
        this.sky = this.add.sprite(0, 0, "sky");
        this.sky.displayWidth = config.width;
        this.sky.setOrigin(0, 0);
    }

    addGround() {
        this.ground = this.matter.add.sprite(config.width / 2, config.height, "ground");
        this.ground.setBody({
            type: "rectangle",
            width: this.ground.displayWidth,
            height: this.ground.displayHeight * 2
        });
        this.ground.setOrigin(0.5, 1);
        this.ground.setStatic(true);
    }
}
