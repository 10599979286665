import React, {useState} from "react";
import './styles.css';
import {Header} from "../../components/Header/index.tsx";
import {Input} from "../../components/common/Input/index.tsx";
import {Button} from "../../components/common/Button/index.tsx";
import {Link} from "react-router-dom";

export const SignIn = ({}) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    return (
        <>
            <Header/>
            <div className="sign-in_container">
                <h2>
                    Авторизация
                </h2>
                <div className="sign-in_form-container">
                    <div className="sign-in_input-item">
                        <Input
                            name="areaWorth"
                            type="text"
                            placeholder="e-mail"
                            value={login}
                            onChange={setLogin}
                            label="введите e-mail"
                        />
                    </div>
                    <div className="sign-in_input-item">
                        <Input
                            name="areaWorth"
                            type="password"
                            placeholder="пароль"
                            value={password}
                            onChange={setPassword}
                            label="введите пароль"
                        />
                    </div>
                    <div className="sign-in_input-item">
                        Нет аккаунта?
                        {' '}
                        <Link
                            className="sign-in_link"
                            to="/realty/signup/"
                        >
                            Зарегистрируйся
                        </Link>
                    </div>
                    <div className="sign-in_input-item">
                        <Button text="войти"/>
                    </div>
                </div>
            </div>
        </>
    );
};
