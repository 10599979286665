export const API_ENDPOINTS = {
    flatsTable: 'https://mgmdk.ru/bot/api/realty/index.php',
    housings: 'https://mgmdk.ru/bot/api/realty/housings.php?getHousings',
    installmentPlanTypes: 'https://mgmdk.ru/bot/api/realty/housings.php?getInstallmentPlanTypes',
    housingPricesByFirstPayment: 'https://mgmdk.ru/bot/api/realty/prices.php?byFirstPayment',
    housingPricesByPeriod: 'https://mgmdk.ru/bot/api/realty/prices.php?byPeriod',
};

export const INSTALLMENT_PLANS_LIB = {
    full: {
        name: 'full',
        label: 'полная стоимость'
    },
    without: {
        name: 'without',
        label: 'без взноса'
    }
};
