import React from 'react';
import {GameComponent} from '../../components/GameComponent/index.tsx';
import {SCENES} from "../../scenes/index.ts";
import {
    selectUserData,
    selectUserPending,
} from "../../store/selectors.ts";
import {connect} from "react-redux";
import {RootState} from "../../store";
import './styles.css';
import {UserBar} from "../../components/UserBar/index.tsx";

export const gameOptions = {
    timeLimit: 3000,
    gravity: 1,
    startCookieHeight: 280,
    cookieHeight: 300,
    cookieRange: [-150, 150],
    cookieSpeed: 1000
}

export const config = {
    type: Phaser.CANVAS,
    parent: 'phaser-container',
    width: 400,
    height: 530,
    scene: SCENES,
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    physics: {
        default: 'matter',
        matter: {
            //debug: true,
            gravity: {
                y: gameOptions.gravity
            }
        }
    }
};

export const _GamePage = ({
                              userData,
                          }) => {
    return <div className="container">
        <UserBar/>
        {userData.id && (
            <GameComponent config={config}/>
        )}
    </div>;
}
const mapStateToProps = (state: RootState) => ({
    userData: selectUserData(state),
    pending: selectUserPending(state),
});

const mapDispatchToProps = {}

export const GamePage = connect(mapStateToProps, mapDispatchToProps)(_GamePage);
