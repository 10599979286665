import {useEffect} from "react";
import {TelegramUserType} from "./types.ts";
import {isTelegram} from "./utils.ts";

export const useSetTelegramUserData = (setUserInfo: (value: TelegramUserType) => void) => {
    useEffect(() => {
        if (isTelegram) {
            const tgUserData = window.Telegram?.WebApp?.initDataUnsafe?.user;
            if (tgUserData?.username) {
                setUserInfo({
                    allowsWriteToPm: tgUserData?.allows_write_to_pm,
                    firstName: tgUserData?.first_name,
                    id: tgUserData?.id,
                    isPremium: tgUserData?.is_premium,
                    languageCode: tgUserData.language_code,
                    lastName: tgUserData?.last_name,
                    userName: tgUserData?.username,
                });
            }
        }
    }, [window]);
}

export const useFetchUserData = ({userInfo, fetchUserData}:
                                     {
                                         userInfo: TelegramUserType;
                                         fetchUserData: (id: number) => void;
                                     }) => {
    useEffect(() => {
        if (userInfo && userInfo?.userName?.length > 0) {
            fetchUserData(userInfo.id);
        }
    }, [userInfo]);
};

export const useCreateUser = ({pending, userInfo, userData, createUser}:
                                  {
                                      pending: boolean;
                                      userInfo: TelegramUserType;
                                      userData: TelegramUserType;
                                      createUser: (value: TelegramUserType) => void;
                                  }) => {
    useEffect(() => {
        if (!pending && !userData?.userName) {
            createUser(userInfo);
        }
    }, [pending, userData]);
}

export const useFetchUserScoreInfo = ({userData, fetchUserScore, fetchUserScoreIndex}:
                                          {
                                              userData: TelegramUserType;
                                              fetchUserScore: (id: number) => void;
                                              fetchUserScoreIndex: (id: number) => void;
                                          }) => {
    useEffect(() => {
        if (userData.id) {
            fetchUserScore(userData.id);
            fetchUserScoreIndex(userData.id);
        }
    }, [userData]);
};
