import React from "react";
import './styles.css';

export const Button = ({
    text,
    iconLeft,
    iconRight,
                       }) => {
    return (
        <button className="button_main">
            {text}
        </button>
    );
}
