import React from "react";
import './styles.css';

export const Input = ({
    value,
    onChange,
    name,
    type,
    placeholder,
    label,
}) => {
    return (
        <>
            <div className="input_label">
                {label}
            </div>
            <input
                className="input_main"
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </>
    );
}
