import React, {useEffect, useState} from "react";
import './styles.css';
import {Tabs} from "../Tabs/index.tsx";
import {ByFirstPayment} from "./ByFirstPayment/index.tsx";
import {ByPeriod} from "./ByPeriod/index.tsx";
import {INSTALLMENT_PLAN_TYPES} from "./constants.ts";
import {RootState} from "../../../store/index.ts";
import {selectInstallmentPlanTypesList, selectInstallmentPlanTypesPending} from "../../../store/selectors.ts";
import {ActionCreator} from "../../../store/actions.ts";
import {connect} from "react-redux";
import {InstallmentPlanSkeleton} from "./InstallmentPlanSkeleton/index.tsx";

export const _InstallmentPlan = ({
                                     housing,
                                     installmentPlans,
                                     pending,
                                     fetchInstallmentPlanTypes,
                                 }) => {
    const [option, setOption] = useState();
    const hasMultiTypes = installmentPlans && Object.keys(installmentPlans)?.length > 1;

    useEffect(() => {
        fetchInstallmentPlanTypes(housing.housingName);
    }, []);

    useEffect(() => {
        if (!!installmentPlans && hasMultiTypes) {
            setOption(INSTALLMENT_PLAN_TYPES.byFirstPayment.id)
        }
        if ( !!installmentPlans && !hasMultiTypes){
            setOption(INSTALLMENT_PLAN_TYPES[Object.keys(installmentPlans)]?.id);
            console.log(INSTALLMENT_PLAN_TYPES[Object.keys(installmentPlans)]?.id)
        }
    }, [installmentPlans]);

    if (pending) {
        return <InstallmentPlanSkeleton />;
    }

    return (
        <>
            <div className="installment_title">
                Рассрочка {INSTALLMENT_PLAN_TYPES[option]?.name}
            </div>
            {hasMultiTypes && (
                <Tabs activeTab={option} setActiveTab={setOption} tabs={installmentPlans}/>
            )}
            {option === INSTALLMENT_PLAN_TYPES.byFirstPayment.id && (
                <ByFirstPayment
                    housingName={housing.housingName}
                />
            )}
            {option === INSTALLMENT_PLAN_TYPES.byPeriod.id && (
                <ByPeriod housingName={housing.housingName}/>
            )}
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    installmentPlans: selectInstallmentPlanTypesList(state),
    pending: selectInstallmentPlanTypesPending(state),
});

const mapDispatchToProps = {
    fetchInstallmentPlanTypes: ActionCreator.fetchInstallmentPlanTypes,
};

export const InstallmentPlan = connect(mapStateToProps, mapDispatchToProps)(_InstallmentPlan);
