import React, {useState} from "react";
import './styles.css';
import {Header} from "../../components/Header/index.tsx";
import {Input} from "../../components/common/Input/index.tsx";
import {Button} from "../../components/common/Button/index.tsx";

export const SignUp = ({}) => {
    const [login, setLogin] = useState('');
    return (
        <>
            <Header/>
            <div className="sign-up_container">
                <h2>
                    Регистрация
                </h2>
                <div className="sign-up_form-container">
                    <div className="sign-up_input-item">
                        <Input
                            name="areaWorth"
                            type="text"
                            placeholder="e-mail"
                            value={login}
                            onChange={setLogin}
                            label="введите e-mail"
                        />
                    </div>
                    <div className="sign-up_input-item">
                        <Input
                            name="areaWorth"
                            type="password"
                            placeholder="пароль"
                            value={login}
                            onChange={setLogin}
                            label="введите пароль"
                        />
                    </div>
                    <div className="sign-up_input-item">
                        <Input
                            name="areaWorth"
                            type="password"
                            placeholder="пароль"
                            value={login}
                            onChange={setLogin}
                            label="повторите пароль"
                        />
                    </div>
                    <div className="sign-up_input-item">
                        <Button text="регистрация"/>
                    </div>
                </div>
            </div>
        </>
    );
};
