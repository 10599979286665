import React, {useEffect, useState} from "react";
import './styles.css'
import {
    getFirstPayment,
    getInstallmentPlans,
} from "./utils.ts";
import {SelectInstallmentOption} from "../components/SelectInstallmentOption/index.tsx";
import {Input} from "../../Input/index.tsx";
import {RootState} from "../../../../store/index.ts";
import {ActionCreator} from "../../../../store/actions.ts";
import {connect} from "react-redux";
import {
    selectPricesByFirstPaymentData,
    selectPricesByFirstPaymentPending,
    selectRequiredPayment
} from "../../../../store/selectors.ts";
import {getAreas, getFlatViewTypes, getFloorRanges, getInstallmentPeriods} from "../utils.ts";
import {ByFirstPaymentSkeleton} from "./components/ByFirstPaymentSkeleton/index.tsx";

export const _ByFirstPayment = ({
                                    fetchPricesByFirstPayment,
                                    pricesByFirstPayment,
                                    requiredPayment,
                                   housingName,
                                    pending,
                               }) => {
    const [area, setArea] = useState(0);
    const [installmentPeriod, setInstallmentPeriod] = useState(0);
    const [areaWorth, setAreaWorth] = useState(0);
    const [firstPayment, setFirstPayment] = useState(0);
    const [fullPrice, setFullPrice] = useState(0);
    const [rest, setRest] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [markup, setMarkup] = useState(0);
    const [installmentOption, setInstallmentOption] = useState();
    const [flatViewType, setFlatViewType] = useState();
    const [floorRange, setFloorRange] = useState();
    const [fullPaymentPrice, setFullPaymentPrice] = useState(0);

    const floorRanges = getFloorRanges(pricesByFirstPayment);
    const flatViewTypes = getFlatViewTypes(floorRange);
    const installmentPeriods = getInstallmentPeriods(floorRange);
    const installmentPlans = getInstallmentPlans(floorRange, installmentPeriod?.value);
    const areas = getAreas(pricesByFirstPayment);
    console.log(pricesByFirstPayment)

    useEffect(() => {
        fetchPricesByFirstPayment(housingName);
    }, []);

    useEffect(() => {
        if (areaWorth > 0 && !!area) {
            setFullPrice(area?.value * areaWorth);
        }
        if (!!installmentPeriod && areaWorth > 0 && firstPayment > 0) {
            console.log(fullPrice, firstPayment)
            setRest(fullPrice - firstPayment);
            setMonthlyPayment(Math.round(rest / installmentPeriod.value));
        }
        setMarkup(fullPrice - fullPaymentPrice);
    });

    useEffect(() => {
        if (installmentOption) {
            setFirstPayment(getFirstPayment(installmentOption, requiredPayment, area?.value, flatViewType));
            setAreaWorth(installmentOption.value?.prices[flatViewType?.value?.id]);
        }
    }, [installmentOption]);

    useEffect(() => {
        setFlatViewType(null);
        setInstallmentPeriod(null);
        setFullPaymentPrice(0);
        setFullPrice(0);
        setArea(null);
    }, [floorRange]);

    useEffect(() => {
        setInstallmentOption(installmentPlans?.full);
    }, [installmentPeriod, flatViewType])

    useEffect(() => {
        if (!!floorRange && !!area) {
            const basePrice = flatViewType ? installmentPlans?.full?.value?.prices[flatViewType.value.id] : 0;
            setFullPaymentPrice(basePrice * area.value);
        }
    }, [floorRange, area, flatViewType]);

    if (pending) {
        return <ByFirstPaymentSkeleton />
    }

    return (
        <>
            <SelectInstallmentOption
                options={floorRanges}
                label="выберите этажи"
                selectedOption={floorRange}
                setSelectedOption={setFloorRange}
            />
            {floorRange && (
                <>
                    <SelectInstallmentOption
                        options={flatViewTypes}
                        label="выберите тип квартиры"
                        selectedOption={flatViewType}
                        setSelectedOption={setFlatViewType}
                    />
                    <SelectInstallmentOption
                        options={areas}
                        label="выберите квадратуру"
                        selectedOption={area}
                        setSelectedOption={setArea}
                    />
                    <SelectInstallmentOption
                        options={installmentPeriods}
                        label="выберите срок рассрочки"
                        selectedOption={installmentPeriod}
                        setSelectedOption={setInstallmentPeriod}
                    />
                    {!!installmentPeriod && (
                        <SelectInstallmentOption
                            options={installmentPlans}
                            selectedOption={installmentOption}
                            setSelectedOption={setInstallmentOption}
                            label="процент первого взноса:"
                        />
                    )}
                </>
            )}
            <div className="first-payment_container">
                <Input
                    name="areaWorth"
                    type="number"
                    placeholder="0 ₽"
                    value={areaWorth > 0 && areaWorth}
                    onChange={setAreaWorth}
                    label="стоимость квадрата:"
                />
                {!!installmentPeriod && (
                    <Input
                        name="firstPayment"
                        type="number"
                        placeholder="0 ₽"
                        value={firstPayment > 0 && firstPayment}
                        onChange={setFirstPayment}
                        label="первоначальный взнос:"
                    />
                )}
                <div className="first-payment_result">
                    полная стоимость:
                    <div className="first-payment_numbers">
                        {fullPrice.toLocaleString()} ₽
                    </div>
                </div>
                {!!installmentPeriod && (
                    <>
                        <div className="first-payment_result">
                            остаток:
                            <div className="first-payment_numbers">
                                {rest.toLocaleString()} ₽
                            </div>
                        </div>
                        <div className="first-payment_result">
                            ежемесячный платеж:
                            <div className="first-payment_numbers">
                                {monthlyPayment.toLocaleString()} ₽
                            </div>
                        </div>
                        {!!markup && (
                            <div className="first-payment_result">
                                наценка:
                                <div className="first-payment_numbers">
                                    {markup.toLocaleString()} ₽
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    pricesByFirstPayment: selectPricesByFirstPaymentData(state),
    requiredPayment: selectRequiredPayment(state),
    pending: selectPricesByFirstPaymentPending(state),
});

const mapDispatchToProps = {
    fetchPricesByFirstPayment: ActionCreator.fetchPricesByFirstPayment,
};

export const ByFirstPayment = connect(mapStateToProps, mapDispatchToProps)(_ByFirstPayment);
