import {RootState} from "./index.ts";

export const selectCommon = (state: RootState) => state;

export const selectHousings = (state: RootState) => selectCommon(state)?.housings;
export const selectHousingsPending = (state: RootState) => selectHousings(state)?.pending;
export const selectHousingsError = (state: RootState) => selectHousings(state)?.error;
export const selectHousingsList = (state: RootState) => selectHousings(state)?.data;

export const selectInstallmentPlanTypes = (state: RootState) => selectCommon(state)?.installmentPlanTypes;
export const selectInstallmentPlanTypesPending = (state: RootState) => selectInstallmentPlanTypes(state)?.pending;
export const selectInstallmentPlanTypesError = (state: RootState) => selectInstallmentPlanTypes(state)?.error;
export const selectInstallmentPlanTypesList = (state: RootState) => selectInstallmentPlanTypes(state)?.data;

export const selectPricesByFirstPayment = (state: RootState) => selectCommon(state)?.housingPricesByFirstPayment;
export const selectPricesByFirstPaymentPending = (state: RootState) => selectPricesByFirstPayment(state)?.pending;
export const selectPricesByFirstPaymentError = (state: RootState) => selectPricesByFirstPayment(state)?.error;
export const selectPricesByFirstPaymentData = (state: RootState) => selectPricesByFirstPayment(state)?.data;
export const selectRequiredPayment = (state: RootState) => selectPricesByFirstPaymentData(state)?.requiredPayment;

export const selectPricesByPeriod = (state: RootState) => selectCommon(state)?.housingPricesByPeriod;
export const selectPricesByPeriodPending = (state: RootState) => selectPricesByPeriod(state)?.pending;
export const selectPricesByPeriodError = (state: RootState) => selectPricesByPeriod(state)?.error;
export const selectPricesByPeriodData = (state: RootState) => selectPricesByPeriod(state)?.data;
